import * as React from "react";
import { Link } from "gatsby";
import { linkWithSlash } from "../lib/functions";

export interface SimulatorCardProps {
  title: string;
  subtitle: string;
  image: string;
  className?: string;
  href?: string;
}

const SimulatorCard: React.FC<SimulatorCardProps> = ({
  className = "",
  href = "#",
  title,
  subtitle,
  image,
}) => {
  return (
    <Link
      to={linkWithSlash(href)}
      className={`${className} inline-block className`}
    >
      <div className="bg-white rounded-md shadow-center p-4 hover:bg-light-100 flex flex-col justify-center items-center space-y-4 h-full w-full">
        <h3 className="text-xl font-bold text-center">
          {title} <br />
          <span className="text-base text-secondary-100 font-bold text-center">
            {subtitle}
          </span>
        </h3>
        <img alt={"Illustration Simulateur"} src={image} className="h-31" />
      </div>
    </Link>
  );
};

export default SimulatorCard;
