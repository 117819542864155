import { graphql } from 'gatsby';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

// components
import CtaButton from '../components/ctabutton';
import Icon from '../components/icon';
import Layout from '../components/layout';
import Seo from '../components/seo';
import SimulatorCard from '../components/simulatorcard';
import VideoModal from '../components/videomodal';

// images
import BgVideo from '../images/thumbnail-video.png';
import VideoIllustration from '../images/video.svg';

// types & interfaces
import type { GatsbyImageType, SEO, PageProps } from '../lib/types';
import type { TooltipProps } from '../components/tooltip';
import { clsx } from '@monemprunt/design-system';

interface HomePageProps {
    title: string;
    subTitle: string;
    headerParagraph: string;
    cover: {
        localFile: GatsbyImageType;
    };
    videoUrl: string;
    SEO: Pick<SEO, 'title' | 'description'>; // SEO type with only 'title' & 'description' fields
    steps: Array<{
        title: string;
        description: string;
        illustration: {
            localFile: {
                publicURL: string;
            };
        };
    }>;
    calculatorSection: {
        title: string;
        cards: Array<{
            title: string;
            subtitle: string;
            link: string;
            illustration: {
                localFile: {
                    publicURL: string;
                };
            };
        }>;
    };
}

const HomePage: React.FC<PageProps<HomePageProps>> = ({
    data: {
        page: {
            title,
            headerParagraph,
            subTitle,
            cover,
            videoUrl,
            steps,
            SEO,
            calculatorSection
        }
    }
}) => {
    const [hideCTA, setHideCTA] = useState(true);

    // check the Scroll position to show or hide the CTA
    const checkScroll = () => {
        let sectionBeforeVideo = document.querySelector('#before-video');
        if (sectionBeforeVideo !== null) {
            let posY = sectionBeforeVideo.getBoundingClientRect().top;

            if (window.scrollY >= posY + 100) {
                setHideCTA(false);
            } else {
                setHideCTA(true);
            }
        }
    };

    useEffect(() => {
        checkScroll();

        window.addEventListener('scroll', checkScroll);
        return () => {
            window.removeEventListener('scroll', checkScroll);
        };
    }, []);

    return (
        <>
            <Seo
                title={SEO.title}
                description={SEO.description}
                pathname={`/`}
            />
            <Layout hideCTA={hideCTA} active={null}>
                <main className="mt-0 pb-15 mb-28 sm:mb-40 md:mb-0">
                    <HeaderSection
                        title={title}
                        subTitle={subTitle}
                        headerParagraph={headerParagraph}
                        cover={cover}
                    />
                    <VideoSection videoUrl={videoUrl} />
                    <ProcessSection steps={steps} />
                    <SimulatorSection calculatorSection={calculatorSection} />
                </main>
            </Layout>
        </>
    );
};

export default HomePage;

const HeaderSection: React.FC<
    Pick<HomePageProps, 'headerParagraph' | 'cover' | 'title' | 'subTitle'>
> = ({ title, subTitle, cover, headerParagraph: description }) => {
    return (
        <section
            id="header"
            className="flex relative flex-col items-center content-center justify-items-center justify-between mt-25 bg-cover bg-center h-96 bg-white sm:h-100">
            {/* Image d'entête */}
            <GatsbyImage
                image={getImage(cover.localFile)}
                alt="Image de présentation de monemprunt.com"
                style={{
                    objectFit: 'cover',
                    width: '100'
                }}
                className="!absolute !inset-0 bottom-0 object-cover h-full"
            />
            {/* Gradient pour l'image d'entête */}
            <div
                className="absolute inset-0 pt-10 lg:pt-30 "
                style={{
                    background:
                        'linear-gradient(rgba(255,255,255,1),rgba(255,255,255,0.5))'
                }}>
                <div className="relative content-center flex flex-col w-full sm:py-8 md:py-16 lg:py-20 items-center">
                    <div className="text-center space-y-5">
                        <h1
                            className="font-bold
              text-lg
              sm:text-2xl
              md:text-3xl
              lg:text-4xl
              ">
                            {title}
                        </h1>
                        {/* Show subtitle and replace new line character ("\n") with a "<br>" tag */}
                        <h2
                            className="font-bold text-secondary-100 text-sm lg:text-base text-center leading-loose px-5
                md:px-10
                lg:px-15
                ">
                            {subTitle.split('\n').map((str) => (
                                <React.Fragment key={str}>
                                    {str} <br />
                                </React.Fragment>
                            ))}
                        </h2>

                        <p className={'text-xs md:text-base font-bold'}>
                            {description.split('\n').map((str) => (
                                <React.Fragment key={str}>
                                    {str} <br />
                                </React.Fragment>
                            ))}
                        </p>

                        <div className="text-center m-auto flex content-center items-center justify-center w-80">
                            <CtaButton className="text-xs h-12 sm:text-sm md:text-base md:h-15" />
                        </div>
                    </div>
                </div>

                <div
                    id="before-video"
                    className="w-full text-center font-bold h-24 sm:h-30 sm:text-xl lg:text-2xl  flex items-end justify-center">
                    <a className="hover:underline" href="#before-video">
                        Comment ça marche ?
                    </a>
                </div>
            </div>
        </section>
    );
};

const VideoSection: React.FC<Pick<HomePageProps, 'videoUrl'>> = ({
    videoUrl
}) => {
    const [open, setOpen] = useState(false);

    return (
        <section
            id="video"
            className=" text-center space-y-8
      my-10
      px-0
      sm:my-20
      sm:px-15
    ">
            <h2 className="font-bold text-lg sm:text-2xl">
                Découvrez notre vidéo explicative
            </h2>
            <div className="relative text-center">
                <img
                    alt={'Illustration Vidéo'}
                    src={VideoIllustration}
                    className="w-full object-center object-contain"
                />
                <div
                    tabIndex={1}
                    onClick={() => setOpen(true)}
                    className="absolute flex items-center justify-center m-auto left-0 right-0
          group shadow-large-center bg-cover group cursor-pointer
          bg-no-repeat
          bg-center
          top-0
          w-[300px] h-[180px]
          sm:top-1/2 sm:bottom-1/2 
          md:bg-100%
          md:w-[450px] md:h-64
           "
                    style={{
                        backgroundImage: `linear-gradient(rgba(255,255,255,0.5),rgba(255,255,255,0.3)),  url(${BgVideo})`
                    }}>
                    <Icon
                        icon="play-solid"
                        className="absolute h-12 shadow-secondary border-white border-2 rounded-full"
                    />
                    <Icon
                        icon="play"
                        className="text-secondary-100 bg-white absolute h-12 shadow-secondary group-hover:hidden border-secondary-100 border-2 rounded-full"
                    />
                    <VideoModal
                        videoUrl={videoUrl}
                        open={open}
                        onClose={() => setOpen(false)}
                    />
                </div>
            </div>
        </section>
    );
};

const ProcessSection: React.FC<{
    steps: Array<{
        title: string;
        description: string;
        illustration: {
            localFile: {
                publicURL: string;
            };
        };
    }>;
}> = ({ steps }) => (
    <section
        className=" text-center my-20
    px-10
    sm:px-15
    md:px-30
    lg:px-48
  ">
        <h2 className="font-bold  mb-10 text-xl sm:text-2xl sm:mb-20">
            Le process en 4 étapes
        </h2>

        <div
            className="grid-cols-2 gap-20 space-y-10 place-content-center place-items-center
     sm:grid sm:space-y-0">
            {/* Steps */}
            {steps.map((step, i) => (
                <React.Fragment key={i}>
                    {i % 2 == 0 && (
                        <img
                            alt={`illustration étape ${step.title}`}
                            src={step.illustration.localFile.publicURL}
                            className="h-64 hidden sm:block"
                        />
                    )}

                    <div className="flex flex-col items-center justify-center space-y-4 text-center sm:text-left ">
                        <h3 className="font-bold w-full text-base sm:text-lg">
                            {step.title}
                        </h3>
                        <p>{step.description}</p>
                    </div>

                    {i % 2 != 0 && (
                        <img
                            alt={`illustration étape ${step.title}`}
                            src={step.illustration.localFile.publicURL}
                            className="h-64 hidden sm:block m-auto"
                        />
                    )}
                </React.Fragment>
            ))}
        </div>
    </section>
);

const SimulatorSection: React.FC<Pick<HomePageProps, 'calculatorSection'>> = ({
    calculatorSection: { title, cards }
}) => {
    return (
        <section
            className="
    px-10
    sm:px-15
    md:mb-48
    lg:mb-30
  ">
            <h2 className="font-bold text-center mb-20 text-xl sm:text-2xl">
                {title}
            </h2>
            <div className="flex items-center justify-center flex-wrap xxl:gap-20">
                {cards.map((card) => (
                    <SimulatorCard
                        key={card.link}
                        title={card.title}
                        subtitle={card.subtitle}
                        className="h-64 w-80 mb-10 md:mr-5 lg:mr-10"
                        href={card.link}
                        image={card.illustration.localFile.publicURL}
                    />
                ))}
            </div>
        </section>
    );
};

export const query = graphql`
    query pageHome {
        page: strapiPageAccueil {
            title: headerText
            subTitle: subheaderText
            headerParagraph
            videoUrl
            cover {
                localFile {
                    publicURL
                    childImageSharp {
                        gatsbyImageData(
                            placeholder: BLURRED
                            formats: [AUTO, WEBP]
                        )
                    }
                }
            }
            steps {
                title
                description
                illustration {
                    localFile {
                        publicURL
                    }
                }
            }
            calculatorSection {
                title
                cards: Card {
                    link
                    subtitle
                    title
                    illustration {
                        localFile {
                            publicURL
                        }
                    }
                }
            }
            SEO {
                description
                title
            }
        }
    }
`;
