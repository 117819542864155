import { Dialog, Transition } from "@headlessui/react";
import * as React from "react";
import { Fragment, useRef } from "react";
import Icon from "./icon";

export interface VideoModalProps {
  open: boolean;
  onClose: () => void;
  videoUrl: string;
}

const VideoModal: React.FC<VideoModalProps> = ({ open, onClose, videoUrl }) => {
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={open}
        style={{
          // @ts-ignore
          zIndex: 500,
        }}
        onClose={onClose}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className="inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all
                my-[50%]
                w-[90%]
                h-[350px]
                sm:max-w-6xl
                sm:my-8 sm:align-middle
                sm:h-[540px]
                md:my-0
             "
            >
              <div className="bg-white px-4 pt-2 pb-4 sm:p-6 sm:pb-4 h-full">
                {/* Close Button */}
                <div className="w-full h-5 flex justify-end">
                  <button
                    onClick={onClose}
                    aria-label="close"
                    className="h-full"
                    ref={cancelButtonRef}
                  >
                    <Icon icon={"close"} className="text-dark-100 h-5 w-5" />
                  </button>
                </div>
                <div className="w-full h-full">
                  {/* Video */}
                  <iframe
                    className="w-full h-[300px] sm:h-[480px]"
                    src={videoUrl}
                    title="Tutoriel monemprunt"
                    frameBorder={0}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default VideoModal;
